<template>
   <div>
       <section id="price-plan" class="price-plan section">
            <div class="container text-center">
                <h2 class="title">我们公司的在线工具</h2>               
                <div class="price-cols row">
                    <div class="item col-md-4 col-sm-4 col-xs-12">
                        <h3 class="heading">阿里云物联平台MQTT密码计算</h3>
                        <div class="content">
                            <p class="price-figure"><span class="currency">主要用于设备登录的用户名及密码计算</span><span class="unit"></span></p>
                            <ul class="list-unstyled feature-list">
                                <li>productKey 产品ID</li>
                                <li>deviceName设备名称</li>
                                <li>deviceSecret设备秘钥</li>
                                <li>timestamp时间戳</li>
                                <li>clientId消息ID</li>
                                <li>method加密方式</li>
                            </ul>
                            <router-link to="/aliyunmqtt">
                              <el-button type="success">工具详细</el-button>
                            </router-link>
                        </div><!--//content-->
                    </div><!--//item--> 
                    


                    <div class="item col-md-4 col-sm-4 col-xs-12">
                        <h3 class="heading">阿里云物联平台MQTTS密码计算</h3>
                        <div class="content">
                            <p class="price-figure"><span class="currency">主要用于一型一密获取设备的deviceSecret计算用户名及密码</span><span class="unit"></span></p>
                            <ul class="list-unstyled feature-list">
                                 <li>productKey 产品ID</li>
                                <li>deviceName设备名称</li>
                                <li>ProductSecret产品秘钥</li>                                
                                <li>random随机数</li>
                                <li>method加密方式</li>
                            </ul>
                            <router-link to="/aliyunmqtttls">
                              <el-button type="success">工具详细</el-button>
                            </router-link>
                        </div><!--//content-->
                    </div><!--//item-->  
                    
                   <div class="item col-md-4 col-sm-4 col-xs-12">
                        <h3 class="heading">湖畔云平台MQTT密码计算</h3>
                        <div class="content">
                            <p class="price-figure"><span class="currency">主要用于设备登录的用户名及密码计算</span><span class="unit"></span></p>
                            <ul class="list-unstyled feature-list">
                                <li>productKey 产品ID</li>
                                <li>deviceName设备名称</li>
                                <li>deviceSecret设备秘钥</li>
                                <li>timestamp时间戳</li>
                                <li>clientId消息ID</li>
                                <li>method加密方式</li>
                            </ul>
                            <router-link to="/nnhpcloudmqtt">
                              <el-button type="success">工具详细</el-button>
                            </router-link>
                        </div><!--//content-->
                    </div><!--//item-->                     
                </div><!--//row-->
            </div><!--//container-->
        </section><!--//price-plan-->
        <Footer />
   </div>
</template>

<script>
import Footer from '../components/footer.vue'
export default {
    data(){
        return{

        }
    },
    components:{
        Footer,
        }
}
</script>

<style>
/* ======= Price Plan Section ======= */
.price-plan {
  padding: 80px 0;
}
.price-plan .title {
  font-size: 28px;
  margin-bottom: 15px;
}
.price-plan .intro {
  font-size: 18px;
  line-height: 1.5;
  color: #999;
  margin-bottom: 30px;
}
.price-plan .item .heading {
  background: #444;
  color: #fff;
  font-size: 22px;
  padding: 10px 0;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  margin-bottom: 0;
  text-transform: uppercase;
  position: relative;
}
.price-plan .item .heading .label {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background: #6dbd63;
  color: #fff;
  font-size: 12px;
  position: absolute;
  padding: 5px 10px;
  right: 15px;
  top: 10px;
}
.price-plan .item .content {
  background: #f5f5f5;
  padding: 30px;
}
.price-plan .item .price-figure .currency {
  font-size: 24px;
  position: relative;
  top: -25px;
}
.price-plan .item .price-figure .number {
  font-family: 'Montserrat', sans-serif;
  font-size: 60px;
}
.price-plan .item .price-figure .unit {
  font-size: 12px;
  color: #bfbfbf;
  text-transform: uppercase;
}
.price-plan .item .feature-list li {
  padding: 10px 0;
}
.price-plan .item .btn {
  font-weight: bold;
  font-size: 16px;
  padding-left: 45px;
  padding-right: 45px;
}
.price-plan .item .btn .extra {
  text-transform: none;
  color: #2e5f28;
  font-weight: normal;
  font-size: 12px;
  font-family: 'Lato', arial, sans-serif;
}
</style>